import { useCallback, useContext } from 'react'
import {DEFAULT_PAGE_SIZE, SearchContext, SearchDispatchContext} from './search-context'

const UseSearch = () => {
  const placeHolder = () => {console.log("PLACEHOLDER FIRED")}

  const {
    search = {},
    page,
    sortDirection,
    sortField,
    pageSize,
    initStatus,
  } = useContext(SearchContext)

  const dispatch = useContext(SearchDispatchContext)

  const setSearch = (search) => {
    dispatch("SET_SEARCH", search)
  }

  const setSortDirection = (dir = "") => {
    dispatch("SORT_DIRECTION", dir)
  }

  const setSortField = (field = "") => {
    dispatch("SORT_FIELD", field)
  }
  const setPageSize = (size = DEFAULT_PAGE_SIZE) => {
    if(dispatch && typeof dispatch === "function") {
      dispatch("PAGE_SIZE", size)
    }
  }

  const setPage =(p = 0) => {
    dispatch("SET_PAGE", p)
  }

  const setParam = (name, value) => {
    dispatch("SET_PARAM", {name, value})
  }

  const setParamList = (params = []) => {
    dispatch("SET_PARAM_LIST", params)
  }

  const replaceParam = (name, value) => {
    dispatch("REPLACE_PARAM", {name, value})
  }

  const removeParam = (name = []) => {
    dispatch("REMOVE_PARAM", name)

  }

  const setSort = useCallback(
    (field) => {
      if (!field) {
        return
      }
      let s = field
      let sd = ''
      if (sortField === field) {
        if (sortDirection === 'asc') {
          sd = 'desc'
        } else if (sortDirection === 'desc') {
          sd = ''
        } else {
          sd = 'asc'
        }
      } else {
        sd = 'asc'
        s = field
      }
      if (sd && s) {
        setSortField(s)
        setSortDirection(sd)
        // dispatch("SORT", `${s}:${sd ?? "asc"}`)
      } else {
        // replaceParam('sort', '')
        // dispatch("SORT", "")
        setSortField('')
        setSortDirection('')
      }
    },
    [sortDirection, sortField, search],
  )

  const clear = (exclude = []) => {
    dispatch("CLEAR_SEARCH", exclude)
  }

  return {
    dispatch: dispatch ?? placeHolder,
    search,
    page,
    setPage,
    setPageSize,
    pageSize,
    setParam,
    replaceParam,
    removeParam,
    setParamList,
    setSort,
    clear,
    sort: { direction: sortDirection, field: sortField },
    initStatus,
  }
}

export default UseSearch

