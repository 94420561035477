export const parseFeatureFlags = () => {
  const f = window._env_['FEATURE_FLAGS'] ?? ""
  return f.split(",")
}

export const isFeatureFlagged = (flag = "") => {
  if(!flag) return false
  const FLAGS = parseFeatureFlags()
  return FLAGS.includes(flag)
}
