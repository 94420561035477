import React, {useRef, useState} from "react";
import {CToastBody, CToaster, CToast, CToastClose} from "@coreui/react";
import {useEventBus} from "../../../hooks/use-event-bus";

const ToastContainer = () => {
  const [toast, setToast] = useState(0)
  const toaster = useRef()
  useEventBus('toasts', ({detail: {title, message}}) => {

    setToast(<CToast>
      <div className={"d-flex"}>
        <CToastBody>{message}</CToastBody>
        <CToastClose className={"me-2 m-auto"}/>
      </div>
    </CToast>)
  })
  return <>
    <CToaster className={"position-static"} placement={"top-end"} push={toast} ref={toaster}/>
  </>
}

export default ToastContainer

