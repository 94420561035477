const EventBus = {
  cancel: (event, callback) => document.removeEventListener(event, callback),
  create: (event, callback) => document.addEventListener(event, callback),
  emit: (event, data) => {
    document.dispatchEvent(new CustomEvent(event, {detail: data}))
  },
}


export default EventBus
