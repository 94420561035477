import React from 'react'
import {SideSegment} from "../index";
import companySize from "../../../tools/company-size";

const CompanySize = ({ segment: inSegment, ...props }) => {
  const customOpts = [
    { name: `Large (${companySize("large")} employees)`, value: 'large' },
    { name: 'Medium/Large', value: 'medium/large' },
    { name: `Medium (${companySize("medium")} employees)`, value: 'medium' },
    { name: `Small (${companySize("small")} employees)`, value: 'small' },
    { name: `Micro (${companySize("micro")} employees)`, value: 'micro' },
    { name: 'Unknown', value: 'unknown' },
  ].filter((s) => inSegment.strings.length === 0 || inSegment.strings.includes(s.value))
  const segment = { ...inSegment, strings: [] }
  return (
    <>
      <SideSegment {...props} segment={segment} customOpts={customOpts} />
    </>
  )
}

export default CompanySize
