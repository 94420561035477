import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CInputGroup,
} from '@coreui/react'

const Segment = ({ segment, segmentSearch = [], onChange, onSelectAll = undefined, ops = {}, showOps = true, customOpts = undefined, showFilter = true }) => {
  const [visible, setVisible] = useState(false)
  const [filter, setFilter] = useState('')
  const [filteredSegs, setFilteredSegs] = useState([])
  const listRef = useRef()

  const [op, setOp] = useState(segment.op)

  const toggleListId = `segment-chooser-${segment.name}`

  useEffect(() => {
    if (filter.length > 0) {
      setFilteredSegs(
        segment.strings.filter((string) => string.toLowerCase().includes(filter.toLowerCase())),
      )
    } else {
      setFilteredSegs(segment.strings ?? [])
    }
  }, [filter, segment])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(ops, segment.name)) {
      setOp(ops[segment.name])
    }
  }, [ops])

  const updateOp = (newOp) => {
    onChange('ops', { name: segment.name, op: newOp })
  }

  const outsideListContext = (e) => {
    if (e.target.id !== toggleListId && !listRef.current?.contains(e.target)) {
      if (visible) {
        setVisible(false)
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', outsideListContext)
    return () => {
      document.body.removeEventListener('click', outsideListContext)
    }
  }, [visible])

  return (
    <div className={'search-segment-container'}>

        <CDropdown
          autoClose={false}
          placement={'bottom-start'}
          direction={'center'}
          visible={visible}
        >
          <CDropdownToggle
            className={`search-segment-toggle ${segment.color}`}
            size={'sm'}
            id={toggleListId}
            onClick={() => setVisible(!visible)}
          >
            {segment.title}
          </CDropdownToggle>
          <CDropdownMenu ref={listRef} style={{minWidth: "6rem"}}>
            {showFilter && <CDropdownHeader>
              <CInputGroup size={'sm'}>
                <CFormInput onChange={(e) => setFilter(e.target.value)} value={filter} />
              </CInputGroup>
              {filteredSegs.length > 0 && typeof onSelectAll === "function" && <div className={"mt-2"} key={`select-all-${segment.name}`}>
                <CButton color={"primary"} size={"sm"} onClick={() => typeof onSelectAll === 'function' && onSelectAll()}>{segmentSearch.length !== filteredSegs.length ? "Select All": "Select None"}</CButton>
              </div>}
            </CDropdownHeader>}

            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
              {customOpts && customOpts.map((item, id) => {
                let active = false

                if(segmentSearch) {
                  active = segmentSearch.includes(item.value)
                }

                return (<CDropdownItem
                  key={`${item.value}-${id}`}
                  active={active}
                  className={"search-segment-item"}
                >
                  <span>{item.name}</span>
                  <span>test</span>
                </CDropdownItem>)
              })}
              {filteredSegs.map((st = '', id) => {
                let active = false
                if(segmentSearch) {
                  active = segmentSearch.includes(st)
                }
                return (
                  <CDropdownItem
                    key={`${st}-${id}`}
                    active={active}
                    //onClick={() => onChange(segment.name, st)}
                    className={"search-segment-item"}
                  >
                    <span>{st}</span>
                    <span>AM TEST</span>
                  </CDropdownItem>
                )
              })}
              {filteredSegs.length === 0 && (!customOpts || customOpts.length === 0) && (
                <CDropdownItem>
                  <em>No terms found</em>
                </CDropdownItem>
              )}
            </div>
          </CDropdownMenu>
        </CDropdown>
    </div>
  )
}

Segment.propTypes = {
  segment: PropTypes.any.isRequired,
  segmentSearch: PropTypes.any.isRequired,
  search: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  showOps: PropTypes.bool,
  showFilter: PropTypes.bool,
}

export default Segment
